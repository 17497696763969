@import "../../scss/variables";

*[class*="SectionWrap_container"] {
    .container {
        padding: 0 !important; // override more specific styles
    }
}

.morelinkcontainer{
    display: flex;
    justify-content: flex-end;
    margin-bottom: -1em;
    a{
        font-size: 14px;
        font-family: $sans-serif-medium;
        font-weight: $sans-medium;
        line-height: 14px;
        letter-spacing: 0.44px;
        text-transform: uppercase;
    }
}

.container {
    display: flex;
    flex-wrap: wrap;
    margin: 48px auto;

    &[data-tilesperrow="one"] {
        justify-content: center;

        .tile {
            max-width: 588px;

            &:nth-child(even) {
                margin-left: 12px;
            }

            &:nth-child(odd) {
                margin-right: 12px;
            }

            &:nth-child(n + 2) {
                margin-top: 24px;
            }
        }
    }

    &[data-tilesperrow="two"] {
        justify-content: center;

        .tile {
            max-width: 588px;
            width: calc(50% - 12px);

            &:nth-child(even) {
                margin-left: 12px;
            }

            &:nth-child(odd) {
                margin-right: 12px;
            }

            &:nth-child(n + 3) {
                margin-top: 24px;
            }
        }
    }

    &[data-tilesperrow="three"] {
        .tile {
            width: calc((100% / 3) - 16px);

            // Every 3rd child starting at 1
            &:nth-child(3n + 1) {
                margin-right: 16px;
            }

            // Every 3rd child starting at 2
            &:nth-child(3n + 2) {
                margin-right: 8px;
                margin-left: 8px;
            }

            // Every 3rd child starting at 3
            &:nth-child(3n + 3) {
                margin-left: 16px;
            }

            // Every child starting at 4
            &:nth-child(n + 4) {
                margin-top: 24px;
            }
        }
    }

    // Does not have background
    &:not([data-hasbackground="true"]) {
        .tile {
            background-color: $lightergrey;
        }
    }

    // Has background
    &[data-hasbackground="true"] {
        background-color: $lightergrey;
        margin-top: 48px;
        margin-bottom: 48px;
        padding: 24px;

        .tile {
            background-color: $white;
        }
    }
}

.tile,
.textContainer {
    display: flex;
    flex-direction: column;
}

.image {
    height: auto;
    width: 100%;
}

.textContainer {
    flex-grow: 1;
    padding: 16px 24px 24px;
}

.titleContainer {
    align-items: center;
    display: flex;
    margin-bottom: 8px;
}

.icon,
.title {
    color: $red;
}

.icon {
    font-size: 30px;
    line-height: 1;
    margin-right: 12px;

    + .title {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 0;
    }
}

.title {
    font-size: 18px;
    font-family: $sans-serif-normal;
    font-weight: $sans-normal;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 8px;
    text-align: left;
    text-transform: none;

    &::after {
        content: none;
    }
}

.description {
    flex-grow: 1;
    font-size: 14px;
    line-height: 22px;
}

.ctaContainer {
    margin-top: 16px;
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    .cta {
        font-size: 16px;
        font-family: $sans-serif-medium;
        font-weight: $sans-medium;
        letter-spacing: 1.5px;
        line-height: 24px;
        text-transform: uppercase;

        + .button {
            margin-top: 12px;
        }
    }    
}

.calendarLinkContainer {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    
    .calendarLink {
        a span {
            font-size: 14px;  
        }
    }
}


.button {
    line-height: 1;
    text-align: center;
    padding: 10px 12px;
    border-bottom: 0px;
    
    &:hover {
        border-bottom: 0px;
    }
}

@media (max-width: $smallContainer) {
    .container {
        &[data-tilesperrow="two"] {
            justify-content: center;

            .tile {
                max-width: 438px;
            }
        }
    }
}

@media (max-width: $mobile-larger) {
    .container {
        &[data-tilesperrow="three"] {
            .tile {
                width: calc(50% - 12px);

                &:nth-child(even) {
                    margin-right: 0;
                    margin-left: 12px;
                }

                &:nth-child(odd) {
                    margin-right: 12px;
                    margin-left: 0;
                }

                &:nth-child(3) {
                    margin-top: 24px;
                }
            }
        }
    }
}

@media (max-width: $mobile) {
    .container {
        &[data-tilesperrow="three"],
        &[data-tilesperrow="two"] {
            display: block;

            .tile {
                max-width: 100%;
                width: 100%;

                &:nth-child(even),
                &:nth-child(odd) {
                    margin-right: 0;
                    margin-left: 0;
                }

                &:nth-child(2) {
                    margin-top: 24px;
                }
            }
        }
    }
}
