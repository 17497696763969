@import '../../scss/variables';
@import '../../scss/mixins';

.spanButton {
    background: transparent;
    border: 1px solid !important;
    display: block;
    font-family: $sans-serif-medium;
    font-weight: $sans-medium;
    text-transform: uppercase;

    // ANIMATION
    position: relative;
    overflow: hidden;
    z-index: 1;

    .buttonEffect {
        border-radius: 50%;
        display: block;
        height: 0;
        position: absolute;
        transition: width 0.2s ease-in, height 0.4s ease-in;
        transform: translate(-50%, -50%);
        width: 0;
        z-index: -1;
    }

    &:hover {
        .buttonEffect {
            height: 600px;
            width: 600px;
        }
    }
}

// SIZES

.spanButton[data-size='large'] {
    font-size: 24px;
    letter-spacing: 1.13px;
    line-height: 24px;
    padding: 20px 16px 18px;
}

.spanButton[data-size='medium'] {
    font-size: 16px;
    letter-spacing: 1.5px;
    padding: 17px 16px 15px;
}

.spanButton[data-size='small'] {
    font-size: 14px;
    letter-spacing: 0.44px;
    line-height: 14px;
    padding: 10px 16px 8px;
}

// COLORS, STYLE (Outline vs Solid)

@each $name, $color in $colors {
    .spanButton[data-color='#{$name}'] {
        // Default outline style
        &[data-solid='false'] {
            border-color: $color;
            color: $color;

            .buttonEffect {
                background-color: $color;
            }

            &:hover {
                color: $grey;
            }

            @if $name != white {
                background-color: $white;

                &:hover {
                    color: $white;
                }
            }
        }

        // Solid style
        &[data-solid='true'] {
            background-color: $color;
            border-color: $color;
            color: $white;

            .buttonEffect {
                background-color: $white;
            }

            &:hover {
                color: $color;
            }
        }

        &:hover {
            background-color: transparent;
        }
    }
}

.spanButton[data-disabled='true'] {
    opacity: 0.6;
    pointer-events: none;
}
a.button {
    border-bottom:none;
}